
.preview-template {
  border-radius: 0;

  .modal-header {
    background: white;
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
