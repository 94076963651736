@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@font-face {
  font-family: 'Poppins';
  src: local('Poppins Light'), local('Poppins-Light'),
    url('font/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Extra';
  src: local('Poppins Extra Bold'), local('Poppins-ExtraBold'),
    url('font/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Thin'), local('Poppins-Thin'),
    url('font/Poppins-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), local('Poppins-Regular'),
    url('font/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Bold'), local('Poppins-Bold'),
    url('font/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Comic Sans MS';
  src: url('font/Comic-Sans-MS.eot');
  src: local('Comic Sans MS'), local('Comic-Sans-MS'),
    url('font/Comic-Sans-MS.eot?#iefix') format('embedded-opentype'),
    url('font/Comic-Sans-MS.woff2') format('woff2'),
    url('font/Comic-Sans-MS.woff') format('woff'),
    url('font/Comic-Sans-MS.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.container {
  .title {
    font-size: 60px;
    font-weight: 700;
    line-height: 89px;

    @media (max-width: $breakpoint-sm) {
      font-size: 60px !important;
      line-height: 70px !important;
      margin-bottom: 28px;
    }

    @media (max-width: $breakpoint-xs) {
      text-align: left;
      font-size: 37px !important;
      line-height: 40px !important;
    }
  }
}

.section {
  padding-left: 165px;
  padding-right: 165px;

  @media screen and (max-width: $breakpoint-md) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

// 107/95 -> 37/40
// 60/88 -> 37/40
// 16/23 -> 14/23
// 20/30 -> 14/23 -- x2
// 20/30 -> 16/23
// 16/23 -> 16/23
// 26/46 -> 14/26
