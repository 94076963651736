@charset "UTF-8";
@font-face {
  font-family: "fontello";
  src: url("../font/fontello.eot?14787577");
  src: url("../font/fontello.eot?14787577#iefix") format("embedded-opentype"), url("../font/fontello.woff2?14787577") format("woff2"), url("../font/fontello.woff?14787577") format("woff"), url("../font/fontello.ttf?14787577") format("truetype"), url("../font/fontello.svg?14787577#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?14787577#fontello') format('svg');
  }
}
*/
[class^=fl-]:before, [class*=" fl-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.fl-ring_community:before {
  content: "";
}

/* '' */
.fl-home:before {
  content: "";
}

/* '' */
.fl-burger:before {
  content: "";
}

/* '' */
.fl-arhive:before {
  content: "";
}

/* '' */
.fl-question:before {
  content: "";
}

/* '' */
.fl-array:before {
  content: "";
}

/* '' */
.fl-book_check:before {
  content: "";
}

/* '' */
.fl-settings:before {
  content: "";
}

/* '' */
.fl-graphic:before {
  content: "";
}

/* '' */
.fl-order:before {
  content: "";
}

/* '' */
.fl-search:before {
  content: "";
}

/* '' */
.fl-users:before {
  content: "";
}

/* '' */
.fl-table:before {
  content: "";
}

/* '' */
.fl-back:before {
  content: "";
}

/* '' */
.fl-bell:before {
  content: "";
}

/* '' */
.fl-chat:before {
  content: "";
}

/* '' */
.fl-check_round:before {
  content: "";
}

/* '' */
.fl-done:before {
  content: "";
}

/* '' */
.fl-group_share:before {
  content: "";
}

/* '' */
.fl-eye_light:before {
  content: "";
}

/* '' */
.fl-bug:before {
  content: "";
}

/* '' */
.fl-gift:before {
  content: "";
}

/* '' */
.fl-key:before {
  content: "";
}

/* '' */
.fl-like:before {
  content: "";
}

/* '' */
.fl-paper:before {
  content: "";
}

/* '' */
.fl-shield_check:before {
  content: "";
}

/* '' */
.fl-trash:before {
  content: "";
}

/* '' */
.fl-world:before {
  content: "";
}

/* '' */
.fl-back_button:before {
  content: "";
}

/* '' */
.fl-small_camera:before {
  content: "";
}

/* '' */
.fl-phone:before {
  content: "";
}

/* '' */
.fl-eye:before {
  content: "";
}

/* '' */
.fl-add_ring:before {
  content: "";
}

/* '' */
.fl-eye_hide:before {
  content: "";
}

/* '' */
.fl-widget_add:before {
  content: "";
}

/* '' */
.fl-share:before {
  content: "";
}

/* '' */
.fl-pined:before {
  content: "";
}

/* '' */
.fl-credit_card:before {
  content: "";
}

/* '' */
.fl-delete:before {
  content: "";
}

/* '' */
.fl-duplicate:before {
  content: "";
}

/* '' */
.fl-filter_big:before {
  content: "";
}

/* '' */
.fl-follow:before {
  content: "";
}

/* '' */
.fl-ipin:before {
  content: "";
}

/* '' */
.fl-link:before {
  content: "";
}

/* '' */
.fl-message:before {
  content: "";
}

/* '' */
.fl-mini_settings:before {
  content: "";
}

/* '' */
.fl-mini:before {
  content: "";
}

/* '' */
.fl-pen:before {
  content: "";
}

/* '' */
.fl-refresh:before {
  content: "";
}

/* '' */
.fl-set:before {
  content: "";
}

/* '' */
.fl-share-1:before {
  content: "";
}

/* '' */
.fl-shield_check-1:before {
  content: "";
}

/* '' */
.fl-sign_in_squre:before {
  content: "";
}

/* '' */
.fl-sign_out_circle_light:before {
  content: "";
}

/* '' */
.fl-smtp:before {
  content: "";
}

/* '' */
.fl-teaser:before {
  content: "";
}

/* '' */
.fl-user_add_alt:before {
  content: "";
}

/* '' */
.fl-user_box_light:before {
  content: "";
}

/* '' */
.fl-user_box:before {
  content: "";
}

/* '' */
.fl-user_cicrle_light:before {
  content: "";
}

/* '' */
.fl-user:before {
  content: "";
}

/* '' */
.fl-users-1:before {
  content: "";
}

/* '' */
.fl-view:before {
  content: "";
}

/* '' */
.fl-world_light:before {
  content: "";
}

/* '' */
.fl-check_ring:before {
  content: "";
}

/* '' */
.fl-bell_bold:before {
  content: "";
}

/* '' */
.fl-search_bold:before {
  content: "";
}

/* '' */
.fl-small_energy:before {
  content: "";
}

/* '' */
.fl-small_gif:before {
  content: "";
}

/* '' */
.fl-small_new:before {
  content: "";
}

/* '' */
.fl-small_play:before {
  content: "";
}

/* '' */
.fl-small_smile:before {
  content: "";
}

/* '' */
.fl-facebook:before {
  content: "";
}

/* '' */
.fl-instagram:before {
  content: "";
}

/* '' */
.fl-info:before {
  content: "";
}

/* '' */
.fl-twitter:before {
  content: "";
}

/* '' */
.fl-arrow_bottom:before {
  content: "";
}

/* '' */
.fl-arrow_left:before {
  content: "";
}

/* '' */
.fl-arrow_right:before {
  content: "";
}

/* '' */
.fl-arrow_top:before {
  content: "";
}

/* '' */
.fl-csv:before {
  content: "";
}

/* '' */
.fl-menu:before {
  content: "";
}

/* '' */
.fl-time:before {
  content: "";
}

/* '' */
.fl-calendar:before {
  content: "";
}

/* '' */
.fl-pin:before {
  content: "";
}

/* '' */
.fl-lock:before {
  content: "";
}

/* '' */
.fl-linkedin:before {
  content: "";
}

/* '' */
.fl-mouse:before {
  content: "";
}

/* '' */
.fl-img_box:before {
  content: "";
}

/* '' */
.fl-book:before {
  content: "";
}

/* '' */
.fl-cpu:before {
  content: "";
}

/* '' */
.fl-darhboard_alt:before {
  content: "";
}

/* '' */
.fl-home-1:before {
  content: "";
}

/* '' */
.fl-setting_line:before {
  content: "";
}

/* '' */
.fl-add:before {
  content: "";
}

/* '' */
.fl-like_filled:before {
  content: "";
}

/* '' */
.fl-pin_small:before {
  content: "";
}

/* '' */
.fl-book_check-1:before {
  content: "";
}

/* '' */
.fl-bookmark:before {
  content: "";
}

/* '' */
.fl-import:before {
  content: "";
}

/* '' */
.fl-link-1:before {
  content: "";
}

/* '' */
.fl-user_add_line:before {
  content: "";
}

/* '' */
.fl-users_line:before {
  content: "";
}

/* '' */
.fl-check_ring_round_light:before {
  content: "";
}

/* '' */
.fl-ring_bold:before {
  content: "";
}

/* '' */
.fl-youtube:before {
  content: "";
}

/* '' */
.fl-vimeo:before {
  content: "";
}

/* '' */
.fl-soundcloud:before {
  content: "";
}

/* '' */
.fl-spotify:before {
  content: "";
}

/* '' */
.fl-burger_right:before {
  content: "";
}

/* '' */
.fl-blockquote:before {
  content: "";
}

/* '' */
.fl-bold:before {
  content: "";
}

/* '' */
.fl-italic:before {
  content: "";
}

/* '' */
.fl-heading-1:before {
  content: "";
}

/* '' */
.fl-heading-2:before {
  content: "";
}

/* '' */
.fl-heading-3:before {
  content: "";
}

/* '' */
.fl-list-bullet:before {
  content: "";
}

/* '' */
.fl-list-number:before {
  content: "";
}

/* '' */
.fl-strike:before {
  content: "";
}

/* '' */
.fl-flag_alt:before {
  content: "";
}

/* '' */
.fl-upload:before {
  content: "";
}

/* '' */
.fl-refresh-1:before {
  content: "";
}

/* '' */
.fl-unfollow:before {
  content: "";
}

/* '' */
.fl-hide-pass-close:before {
  content: "";
}

/* '' */
.fl-hide-pass:before {
  content: "";
}

/* '' */
.fl-arrow_right-1:before {
  content: "";
}

/* '' */
.fl-avatar_fill:before {
  content: "";
}

/* '' */
.fl-to-end-alt:before {
  content: "";
}

/* '' */
.fl-chat_plus:before {
  content: "";
}

/* '' */
.fl-to-end:before {
  content: "";
}

/* '' */
.fl-camera:before {
  content: "";
}

/* '' */
.fl-close_ring:before {
  content: "";
}

/* '' */
.fl-copy_small:before {
  content: "";
}

/* '' */
.fl-copy:before {
  content: "";
}

/* '' */
.fl-eye_fill:before {
  content: "";
}

/* '' */
.fl-eye-1:before {
  content: "";
}

/* '' */
.fl-filter_fill:before {
  content: "";
}

/* '' */
.fl-flag_fill:before {
  content: "";
}

/* '' */
.fl-lock_fill:before {
  content: "";
}

/* '' */
.fl-mail_fill:before {
  content: "";
}

/* '' */
.fl-pencil:before {
  content: "";
}

/* '' */
.fl-person_fill:before {
  content: "";
}

/* '' */
.fl-phone_fill:before {
  content: "";
}

/* '' */
.fl-plus:before {
  content: "";
}

/* '' */
.fl-revert:before {
  content: "";
}

/* '' */
.fl-time_fill:before {
  content: "";
}

/* '' */
.fl-check_ring_fill:before {
  content: "";
}

/* '' */
.fl-chat_fill:before {
  content: "";
}

/* '' */
.fl-camera-alt:before {
  content: "";
}

/* '' */
.fl-picture:before {
  content: "";
}

/* '' */
.fl-flash:before {
  content: "";
}

/* '' */
.fl-adjust:before {
  content: "";
}

/* '' */
.fl-tint:before {
  content: "";
}

/* '' */
.fl-images:before {
  content: "";
}

/* '' */
.fl-image-portrait-solid:before {
  content: "";
}

/* '' */
.fl-file-image-solid:before {
  content: "";
}

/* '' */
.fl-clone-solid:before {
  content: "";
}

/* '' */
.fl-vector-121:before {
  content: "";
}

/* '' */
.fl-close_ring_light:before {
  content: "";
}

/* '' */
.fl-expand_down:before {
  content: "";
}

/* '' */
.fl-round_check:before {
  content: "";
}

/* '' */
.fl-videocam:before {
  content: "";
}

/* '' */
.fl-basket:before {
  content: "";
}

/* '' */
.fl-expand_right:before {
  content: "";
}

/* '' */
.fl-expand_up:before {
  content: "";
}

/* '' */
.fl-code:before {
  content: "";
}

/* '' */
.fl-desk:before {
  content: "";
}

/* '' */
.fl-send:before {
  content: "";
}

/* '' */
.fl-blank:before {
  content: "";
}

/* '' */
.fl-add_round:before {
  content: "";
}

/* '' */
.fl-grip-dots-vertical:before {
  content: "";
}

/* '' */
.fl-play_fill:before {
  content: "";
}

/* '' */
.fl-check:before {
  content: "";
}

/* '' */
.fl-stop_fill:before {
  content: "";
}

/* '' */
.fl-check_fill:before {
  content: "";
}

/* '' */
.fl-check_fill_square:before {
  content: "";
}

/* '' */
.fl-folder_send_light:before {
  content: "";
}

/* '' */
.fl-folder_add:before {
  content: "";
}

/* '' */
.fl-send_hor:before {
  content: "";
}

/* '' */
.fl-message_open:before {
  content: "";
}

/* '' */
.fl-dotted:before {
  content: "";
}

/* '' */
.fl-vert-setting:before {
  content: "";
}

/* '' */
.fl-email:before {
  content: "";
}

/* '' */
.fl-check_circle:before {
  content: "";
}

/* '' */
.fl-calendar-1:before {
  content: "";
}

/* '' */
.fl-expand_left:before {
  content: "";
}

/* '' */
.fl-chat_alt_2_light:before {
  content: "";
}

/* '' */
.fl-filter_alt:before {
  content: "";
}

/* '' */
.fl-form:before {
  content: "";
}

/* '' */
.fl-pic:before {
  content: "";
}

/* '' */
.fl-setting_alt_line:before {
  content: "";
}

/* '' */
.fl-info-1:before {
  content: "";
}

/* '' */
.fl-table-1:before {
  content: "";
}

/* '' */
.fl-emo-angry:before {
  content: "";
}

/* '' */
.fl-info_fill:before {
  content: "";
}

/* '' */
.fl-widget_add-1:before {
  content: "";
}

/* '' */
.fl-time_atack_fill:before {
  content: "";
}

/* '' */
.fl-video_light:before {
  content: "";
}

/* '' */
.fl-out:before {
  content: "";
}

/* '' */
.fl-menu-1:before {
  content: "";
}

/* '' */
.fl-logout-02:before {
  content: "";
}

/* '' */
.fl-mail-04:before {
  content: "";
}

/* '' */
.fl-pin-1:before {
  content: "";
}

/* '' */
.fl-file_dock:before {
  content: "";
}

/* '' */
.fl-notebook:before {
  content: "";
}

/* '' */
.fl-lightning:before {
  content: "";
}

/* '' */
.fl-widget:before {
  content: "";
}

/* '' */
.fl-back-1:before {
  content: "";
}

/* '' */
.fl-add_square:before {
  content: "";
}

/* '' */
.fl-star:before {
  content: "";
}

/* '' */
.fl-send-02:before {
  content: "";
}

/* '' */
.fl-twitter-1:before {
  content: "";
}

/* '' */
.fl-facebook-1:before {
  content: "";
}

/* '' */
.fl-right-circled:before {
  content: "";
}

/* '' */
.fl-linkedin-1:before {
  content: "";
}

/* '' */
.fl-angle-circled-right:before {
  content: "";
}

/* '' */