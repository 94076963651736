
.card {
  margin: 0 10px;
  filter: grayscale(100%);
  transition: 0.5s;

  &:hover {
    filter: grayscale(0);
  }
}
