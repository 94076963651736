
.section {
  padding-bottom: 32px;

  .bar {
    padding-top: 13px;
    padding-bottom: 13px;

    .logo {
      width: 55px;
      overflow: hidden;
      ::v-deep {
        .logo {
          width: 55px;
        }
      }
    }
    .close-btn {
      margin-left: 20px;
    }
  }

  .a-btn::v-deep .q-btn__wrapper {
    padding: 5px 19px !important;
  }

  .content {
    padding: 35px 0;

    .link {
      font-weight: bold;
      display: block;
      margin-bottom: 30px;

      font-size: 16px;
      font-weight: 700;
      line-height: 24px;

      color: black !important;
      font-weight: bold;
      text-transform: uppercase !important;
      margin-left: 0;
      width: fit-content;

      ::v-deep .q-btn__wrapper {
        padding: 0 !important;
      }
    }
  }
}
