:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.share-code-dialog.modal-content {
  width: 480px;
}
.share-code-dialog.modal-content .modal-header {
  background-color: #F8F8F8;
}
.share-code-dialog.modal-content .modal-body {
  padding: 25px 20px;
}
.share-code-dialog .sent-block {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
  padding: 80px 82px 95px 82px;
}
.share-code-dialog .sent-block-message {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.share-code-dialog .sent-block-message a {
  text-decoration: none;
}
.share-code-dialog .buttons {
  display: flex;
  gap: 20px;
}
.share-code-dialog .buttons button:nth-child(1) {
  flex: 1;
}
.share-code-dialog .buttons button:nth-child(2) {
  flex: 2;
}
.share-code-dialog .buttons button:nth-child(2) .q-btn__content {
  justify-content: space-between;
}