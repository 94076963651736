:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.sms-tariff-dialog .modal-header {
  background-color: #F8F8F8;
}
.sms-tariff-dialog .modal-body {
  overflow: auto;
}
.sms-tariff-dialog .tariff-container {
  max-height: calc(100vh - 96px - 60px);
}
.sms-tariff-dialog .tariff-container .reminder-types {
  position: sticky;
  top: 0;
  z-index: 1;
}
.sms-tariff-dialog .tariff {
  border: 1px solid #E3E3E3;
  transition: all 0.5s ease;
}
.sms-tariff-dialog .tariff:hover {
  background: rgba(0, 214, 162, 0.2) !important;
  border: 1px solid #00D6A2;
}
.sms-tariff-dialog .tariff:hover .q-badge {
  background: #fff !important;
}
.sms-tariff-dialog .tariff:hover .dialog-info > div:first-child {
  border-bottom: 1px solid #00D6A2;
}
.sms-tariff-dialog .dialog-img .q-badge {
  position: absolute;
  top: -2px;
  left: 14px;
  font-size: 11px;
  font-weight: 700;
  padding: 5px 9px;
  transition: all 0.5s ease;
}
.sms-tariff-dialog .dialog-info > div:first-child {
  border-bottom: 1px solid #E3E3E3;
  padding-bottom: 11px;
  transition: all 0.5s ease;
}
.sms-tariff-dialog .dialog-info > div:last-child {
  padding-top: 11px;
}