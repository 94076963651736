:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.avatar-img {
  border-radius: 50%;
}

.card {
  font-family: Comic Sans MS;
  border-radius: 30px;
  line-height: 46px;
  flex: 1;
  align-self: normal;
  padding: 70px 100px 61px;
}
@media screen and (max-width: 1023px) {
  .card {
    max-width: 40rem;
    margin: 0 auto;
  }
}
@media screen and (max-width: 599px) {
  .card {
    padding: 39px 25px 30px;
  }
}

.img {
  top: -25px;
}

.text {
  max-width: 700px;
  font-size: 26px;
  font-weight: 400;
  line-height: 46px;
}
@media screen and (max-width: 599px) {
  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
  }
}

.line {
  width: 150px;
  height: 2px;
  margin-top: 40px;
  margin-bottom: 40px;
}
@media screen and (max-width: 599px) {
  .line {
    margin-top: 29px;
    margin-bottom: 29px;
  }
}

.name {
  height: 30px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
@media screen and (max-width: 599px) {
  .name {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
}

.desc {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
}
@media screen and (max-width: 599px) {
  .desc {
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
  }
}