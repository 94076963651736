:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.sms-banner {
  background: #00D6A2;
}
.sms-banner .rocket-img {
  position: absolute;
  top: -2px;
  left: 310px;
}
.sms-banner .arrow-img {
  position: absolute;
  top: 34px;
  right: 342px;
}