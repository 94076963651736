.save-block,
.settings-page__panel,
.form-fields-page__panel,
.notifications-page__panel,
.thank-you__panel,
.autoresponders-page__panel,
.design-page__panel,
.validation-page__panel,
.code-page__panel {
  @media (min-width: 0) {
    max-width: $form-width;
  }

  @media (min-width: $breakpoint-md) {
    width: $form-width;
    max-width: unset;
  }
}

.autoresponders-page__panel {
  .integration {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__provider-field {
      display: grid;
      grid-template-columns: 1fr 2fr;
      align-items: center;
      gap: 20px;

      &_day-cycle {
        grid-template-columns: 1fr 2fr 59px;
      }

      .app-input,
      .app-select {
        display: unset;
      }
    }
  }
}

.q-toggle__inner--falsy {
  .q-toggle__track {
    background: $grey-2;
  }
}

.app-input .q-field {
  align-items: center;
}

/**
 * Common builder components
 */
.app-input_textarea {
  align-items: start !important;
  grid-template-columns: 1fr 2.3fr;

  & > div {
    // Label
    display: flex;
    align-items: center;
  }

  .q-field__control {
    padding: 15px 20px !important;
    min-height: unset;

    textarea.q-field__native {
      line-height: 19px !important;
      padding: 0;
      min-height: 37px;
    }
  }
}

.img-selector {
  display: flex;
  justify-content: space-between;

  .option {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 15px;
    padding: 20px 20px 16px;
    border-radius: 10px;
    transition: all 0.5s ease;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
      background: $grey-6;
      border: 1px solid $grey-6;
    }

  }

  .active {
    border-color: $primary !important;
    background: rgba(47, 241, 195, 0.2) !important;
  }

  .active .option-img {
    border-color: $primary;
  }

  .option-img {
    background: #fff;
    border-radius: 10px;
    border: 1px solid $grey-2;
    transition: all 0.5s ease;
  }
}

.icon-selector {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: center;
  gap: 10px;

  .q-btn-toggle {
    .q-btn__content {
      font-size: 15px;
      font-weight: 400;
    }

    .bg-btn-toggle-selected {
      background: rgba(47, 241, 195, 0.2);
      border-color: $primary;
    }
  }
}
