:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

#faq {
  border-bottom: 1px solid #E3E3E3;
  padding-top: 60px;
  padding-bottom: 80px;
}
#faq ::v-deep .q-item__section--avatar {
  display: none;
}
#faq ::v-deep .q-expansion-item {
  padding-left: 0;
  padding-right: 0;
}
#faq ::v-deep .q-item__label {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
@media screen and (max-width: 599px) {
  #faq ::v-deep .q-item__label {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
}