
.time-reminding {
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr !important;
  text-align: center;

  label {
    grid-column-start: 1;
  }
}
