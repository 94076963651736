:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

#forms {
  border-bottom: 1px solid #E3E3E3;
  padding-top: 40px;
  padding-bottom: 80px;
}
@media screen and (max-width: 1023px) {
  #forms .content {
    display: flex;
    flex-direction: column-reverse;
  }
}