:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.a-btn {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}
@media screen and (max-width: 1023px) {
  .a-btn ::v-deep .q-btn__wrapper {
    padding: 16px 27px !important;
  }
}
.a-btn ::v-deep .q-btn__content div {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}
@media screen and (max-width: 1023px) {
  .a-btn ::v-deep .q-btn__content div {
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
  }
}
.a-btn.green {
  padding: 2px;
}
.a-btn.white-bordered {
  border: solid 2px #00D6A2;
}
.a-btn.white {
  border: solid 2px #00D6A2;
}