@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@import "quasar/src/css/variables";
@import "quasar.variables";
@import "~common/css/app";
@import "pages";

.toggle-buttons {
  box-shadow: none;
  .q-btn__content {
    text-transform: capitalize !important;
  }
  .bg-btn-toggle-selected {
    background: rgba(47, 241, 195, 0.2);
    border-color: $primary !important;
  }
}

.grid {
  display: grid;
  align-items: center;
  gap: 20px;

  &-2 {
    @extend .grid;
    grid-template-columns: 1fr 2fr;
  }

  &-3 {
    @extend .grid;
    grid-template-columns: 1.7fr 2.5fr 1fr !important;

    .app-input {
      display: unset;
    }
  }
}
