:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.action-wrapper .bg-img {
  z-index: 0;
}
@media screen and (max-width: 599px) {
  .action-wrapper .bg-img {
    position: relative;
  }
}
.action-wrapper #action {
  padding-top: 60px;
  padding-bottom: 140px;
  background-size: cover;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 599px) {
  .action-wrapper #action {
    padding-bottom: 17px;
  }
}
.action-wrapper #action .title {
  width: 100%;
  max-width: 550px;
}
.action-wrapper #action .desc {
  width: 100%;
  max-width: 300px;
  font-size: 28px;
}
@media screen and (max-width: 599px) {
  .action-wrapper #action .desc {
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
  }
}