:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.child-menu {
  background: #3A4542;
}
.child-menu hr {
  margin: 0;
  background: #4F5856 !important;
}
.child-menu .list-link {
  background: unset;
  border-radius: 0 !important;
  padding: 9px 20px !important;
  margin: 0;
  transition: all 0.25s ease;
}
.child-menu .list-link:last-of-type .q-item__label {
  color: #00D6A2 !important;
  font-size: 14px !important;
}
.child-menu .list-link:last-of-type .q-icon {
  color: #00D6A2 !important;
}
.child-menu .list-link:hover {
  background: #4F5856;
}
.child-menu .list-link .q-item__label {
  color: white !important;
  font-size: 14px !important;
}