:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.background {
  background-color: #F4F4F4;
  width: 100%;
  height: 500px;
  border-radius: 20px;
}

.content {
  top: 0;
  left: 0;
}

.dash-affiliate-id {
  min-width: 400px;
  border: 1px solid #00D6A2;
  border-radius: 14px;
}
.dash-affiliate-id ::v-deep .q-card__section i {
  color: black !important;
}

.table-top {
  height: 60px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  border: 1px solid #E3E3E3;
  border-bottom: none;
}

.table {
  box-shadow: none;
  border: 1px solid #E3E3E3;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}
.table .actions {
  width: 50px;
}
.table ::v-deep th:first-child {
  padding-left: 30px;
}
.table ::v-deep tr {
  height: 60px;
}