:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.revenue-wrapper .bg-img {
  position: relative;
  margin-top: -35%;
}

#revenue {
  background: url("~assets/landing/screen.png") right no-repeat;
  position: relative;
  z-index: 1;
  padding-top: 90px;
  padding-bottom: 160px;
}
@media screen and (max-width: 1023px) {
  #revenue {
    background: transparent;
    text-align: center;
    padding-top: 90px;
    padding-bottom: 20px;
  }
}
#revenue .title {
  font-size: 107px;
  font-weight: 700;
  line-height: 95px;
}
@media screen and (max-width: 1023px) {
  #revenue .title {
    text-align: center;
  }
}
#revenue .title-border,
#revenue .subtitle-border {
  height: 2px;
  background: #000;
}
@media screen and (max-width: 1023px) {
  #revenue .title-border,
#revenue .subtitle-border {
    margin-left: auto;
    margin-right: auto;
  }
}
#revenue .title-border {
  width: 100%;
  max-width: 570px;
}
#revenue .subtitle-border {
  width: 58%;
  max-width: 253px;
}
#revenue .subtitle {
  font-size: 30px;
  font-weight: 700;
}
@media (max-width: 599px) {
  #revenue .subtitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
  }
}
#revenue .start {
  gap: 40px;
}
@media screen and (min-width: 1023px) and (max-width: 1173px) {
  #revenue .start {
    width: min-content;
  }
}
#revenue .start__text {
  font-size: 18px;
  font-weight: 400;
  line-height: 31px;
  text-align: left;
}
@media (max-width: 1023px) {
  #revenue .start {
    align-items: center;
    width: fit-content;
    margin: 0 auto;
    gap: 20px;
  }
  #revenue .start__text {
    font-size: 11px;
    font-weight: 400;
    line-height: 17px;
  }
}