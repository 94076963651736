:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.sidebar {
  width: 300px;
}
.sidebar .header {
  border: 1px solid #E3E3E3;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.sidebar .body {
  border: 1px solid #E3E3E3;
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.sidebar .body ::v-deep .q-panel {
  overflow: unset;
}
.sidebar .block ::v-deep .q-toggle--dense .q-toggle__label {
  padding-left: 16px;
}
.sidebar .design .q-item {
  min-height: unset;
}
.sidebar .drag-icon {
  color: #D5D5D5;
}