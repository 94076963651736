
.validation-page__panel {
  .form-builder__option_horizontal {
    .app-input,
    .app-select {
      grid-template-columns: 1fr 2.76fr;
    }
  }

  .color-picker {
    max-width: unset;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1.35fr !important;
    gap: 20px;

    label {
      align-items: center;
    }
  }
}
