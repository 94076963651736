
.design-page {
  &__panel:not(:last-child) {
    margin-bottom: 20px;
  }

  .color-picker {
    max-width: unset;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1.35fr;
    gap: 20px;

    label {
      align-items: center;
    }
  }
}

.page-hint {
  &-primary-color {
    top: 189px;
  }
}
