:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

::v-deep .q-card {
  border-radius: 20px;
  transition: all 0.5s ease;
}
::v-deep .q-card.not-configured {
  border-color: #DB6F6F;
}
::v-deep .q-card:not(.active) {
  background: #F4F4F4;
}
::v-deep .q-card:not(.active) .percent {
  border-color: #E3E3E3;
}
::v-deep .q-card .percent {
  transition: all 0.5s ease;
  border: 1px solid transparent;
}
::v-deep .q-card .form-toggle .q-toggle__track,
::v-deep .q-card .form-toggle .q-toggle__inner {
  height: 20px;
  min-width: 26px;
  width: 26px;
}
::v-deep .q-card .form-toggle .q-toggle__thumb {
  width: 16px;
  height: 16px;
  top: 2px;
  left: 2px;
}
::v-deep .q-card .form-toggle .q-toggle__inner--truthy .q-toggle__thumb {
  right: 2px;
  left: unset;
}