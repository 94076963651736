
::v-deep .form-field {
  &:not(:last-child) {
    border-bottom: 1px solid #E3E3E3;
  }

  .body {
    background: unset;
    padding: 0;
    margin: 0;

    .q-field__control {
      background: #fff;
    }

    .form-builder__option_horizontal {
      .app-input,
      .app-select {
        grid-template-columns: 1fr 2.6fr;
      }
    }
  }
}
