:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.features {
  margin-bottom: 80px;
  --feature-max-width: 484px;
}
@media screen and (max-width: 1023px) {
  .features {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}
.features .features-bg-img {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}
@media screen and (max-width: 1023px) {
  .features .features-bg-img {
    height: unset;
    width: 100%;
    max-width: var(--feature-max-width);
    position: unset;
  }
}
.features .features_wrapper {
  width: 100%;
  max-width: var(--feature-max-width);
  position: relative;
  z-index: 1;
}
.features .features_wrapper .subtitle {
  font-size: 30px;
  font-weight: 700;
  margin-top: 48px;
  margin-bottom: 48px;
}
@media screen and (max-width: 1023px) {
  .features .features_wrapper .subtitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.features .features_wrapper .item {
  display: flex;
  gap: 6px;
  margin-top: 24px;
}
@media screen and (max-width: 1023px) {
  .features .features_wrapper .item {
    margin-top: 20px;
  }
}
.features .features_wrapper .item .features__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}
@media screen and (max-width: 1023px) {
  .features .features_wrapper .item .features__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
  }
}