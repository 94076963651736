:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

#footer {
  padding-top: 30px;
  padding-bottom: 30px;
  background: none;
}
@media screen and (max-width: 599px) {
  #footer .logo__wrapper {
    width: 55px;
    overflow: hidden;
  }
}
#footer .btn ::v-deep .q-btn__wrapper {
  padding: 5px 17px !important;
}
#footer .menu {
  min-height: 90px;
  border-top: 1px solid #E3E3E3;
  border-bottom: 1px solid #E3E3E3;
}
#footer .menu .link {
  font-weight: 700;
  text-decoration: none;
}
@media screen and (max-width: 599px) {
  #footer .menu {
    display: block;
  }
  #footer .menu .nav {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 30px 0;
  }
  #footer .menu .for-support-text {
    display: block;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 599px) {
  #footer .copyright {
    display: block;
    margin-bottom: 40px;
  }
  #footer .copyright div:first-child {
    margin-bottom: 20px;
  }
}