:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.feature {
  min-height: 173px;
  padding: 0 30px 40px;
  margin-bottom: 50px;
  border-bottom: 2px solid #000;
  text-align: center;
  transition: all 1.8s;
}
.feature__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
@media screen and (max-width: 1023px) {
  .feature__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
}
.feature .item {
  transform: translateY(0);
  transition: all 1.8s;
}
.feature:hover {
  border-bottom: 2px solid #00D6A2;
}
.feature:hover .item {
  color: #00D6A2;
  transform: translateY(-15px);
}