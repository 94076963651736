:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

#reviews {
  border-bottom: 1px solid #E3E3E3;
  padding-top: 60px;
  padding-bottom: 80px;
}
#reviews .carousel {
  height: 100%;
  padding-top: 28px;
  margin-bottom: 61px;
}
@media screen and (max-width: 599px) {
  #reviews .carousel {
    margin-bottom: 31px;
  }
}
#reviews .carousel .cards-container {
  gap: 30px;
}
#reviews ::v-deep .q-carousel__slide {
  padding: 0;
}
#reviews ::v-deep .q-panel {
  overflow: initial;
}
#reviews ::v-deep .q-btn {
  margin: 0;
  background: none !important;
}
@media screen and (max-width: 1023px) {
  #reviews ::v-deep .q-pagination {
    width: 100%;
    max-width: 20rem;
    justify-content: center;
  }
}
#reviews ::v-deep .q-pagination__content > .q-btn:first-child {
  margin-right: 20px;
  font-size: 8px !important;
}
#reviews ::v-deep .q-pagination__content > .q-btn .q-btn__wrapper {
  padding: 18px 10px !important;
}
@media screen and (max-width: 1023px) {
  #reviews ::v-deep .q-pagination__middle {
    flex: 1;
    visibility: hidden;
  }
}
#reviews ::v-deep .q-pagination__middle > .q-btn .q-btn__wrapper {
  padding: 10px 2px !important;
}
@media screen and (max-width: 1023px) {
  #reviews ::v-deep .q-pagination__content {
    width: 100%;
  }
}
#reviews ::v-deep .q-pagination__content > .q-btn:last-child {
  margin-left: 20px;
  font-size: 8px !important;
}
#reviews ::v-deep .q-btn__wrapper::before {
  box-shadow: none;
}
#reviews ::v-deep .q-focus-helper {
  opacity: 0;
  background: none;
}
#reviews ::v-deep .q-btn__wrapper {
  border-bottom: 2px solid;
  border-radius: 0;
}