
.editing-dialog {
  .q-tab__label {
    padding: 5px 0;
  }

  .q-panel {
    overflow: unset;
  }
}
