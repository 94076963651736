:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

@media (min-width: 0) {
  .setting-column {
    width: 453px;
  }
}
@media (min-width: 1439px) {
  .setting-column {
    width: 780px;
    max-width: unset;
  }
}
.setting-column.thank-you-page {
  width: 453px !important;
}

.form-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 111px;
}

::v-deep.copy-btn .q-btn-dropdown__arrow-container .q-icon {
  font-size: 19px;
  padding: 16px;
}