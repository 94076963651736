:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.scroll-area {
  height: 150px;
}
.scroll-area__content {
  display: flex;
}
.scroll-area__content ::v-deep .card:not(:last-child) {
  border-right: 1px solid #E3E3E3;
}
.scroll-area ::v-deep .q-scrollarea__thumb {
  display: none;
}