:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.cards-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 1rem;
  row-gap: 24px;
}
@media screen and (max-width: 1023px) {
  .cards-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1023px) {
  .cards-wrapper ::v-deep .card:not(:nth-child(4n)) {
    border-right: 1px solid #E3E3E3;
  }
}
@media screen and (max-width: 1023px) {
  .cards-wrapper ::v-deep .card:not(:nth-child(2n)) {
    border-right: 1px solid #E3E3E3;
  }
}