:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.link-not-available-page {
  height: calc(100% - 115px);
}
.link-not-available-page .ops {
  font-size: 470px;
  font-weight: 700;
  color: #F8F8F8;
  line-height: 0;
}
.link-not-available-page .text {
  top: 0;
}