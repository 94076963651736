
.add-form-btn {
  min-height: 220px;
}
.expired-block {
  width: 100%;
  &-desc {
    .title {
      font-size: 37px;
      font-weight: 700;
      width: 300px;
    }
    .hint {
      width: 150px;
    }
  }
}
