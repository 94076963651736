:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.form-fields-page__panel ::v-deep .form-field:not(:last-child) {
  border-bottom: 1px solid #E3E3E3;
}
.form-fields-page__panel ::v-deep .form-field .drag-icon {
  color: #D5D5D5;
  cursor: pointer;
}
.form-fields-page__panel ::v-deep .form-field .body .q-field__control {
  background: #fff;
}
.form-fields-page__panel ::v-deep .form-field .body .form-builder__option_horizontal .app-input,
.form-fields-page__panel ::v-deep .form-field .body .form-builder__option_horizontal .app-select {
  grid-template-columns: 1fr 2.6fr;
}
.form-fields-page__panel .sortable-ghost {
  box-shadow: 0 0 5px 0 #E3E3E3;
  transition: all 0.5s ease;
}