
.cards-img {
  min-width: 260px;
  max-width: 500px;
}

.scroll-area {
  height: 256px;

  ::v-deep {
    .q-scrollarea__content {
      padding-top: 16px;
    }
    .q-scrollarea__thumb {
      display: none;
    }
  }
}

.feature {
  width: 175px;
  margin-right: 30px;
  margin-bottom: 0;

  ::v-deep {
    height: unset;
  }
}
