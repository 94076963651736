:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.feature-selector {
  --height: 56px;
}
.feature-selector .scroll-area {
  height: calc(var(--height) - 1px);
  flex: 1;
}
.feature-selector .scroll-area__content {
  margin: 0 auto;
  width: fit-content;
}
.feature-selector .scroll-area ::v-deep .q-scrollarea__thumb {
  display: none;
}
.feature-selector .type-feature {
  height: var(--height);
  border-bottom: 2px solid #000;
  cursor: pointer;
  transition: all 0.25s ease;
}
.feature-selector .type-feature:not(.active):hover {
  border-bottom-color: #929292;
  color: #929292;
}
.feature-selector .type-feature.active {
  border-bottom-color: #00D6A2;
  color: #00D6A2;
}
.feature-selector .type-feature-item {
  min-width: 170px;
}
@media screen and (max-width: 1023px) {
  .feature-selector .type-feature-control {
    display: none;
  }
}