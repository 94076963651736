:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.separator {
  position: relative;
}
.separator:after {
  background: #B3B3B3;
  content: "";
  height: 16px;
  position: absolute;
  right: -16px;
  width: 1px;
}