:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.boost-conversion-menu {
  border-radius: 20px;
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.05);
  width: 226px;
  overflow: visible;
}
.boost-conversion-menu .body {
  border-bottom: 1px solid #E3E3E3;
}
.boost-conversion-menu .body .rocket-img {
  position: absolute;
  width: 100%;
  left: 0;
  top: -20px;
}
.boost-conversion-menu .footer button {
  border-radius: 10px;
}