:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.render .container {
  max-width: 900px;
  margin: 0 auto;
}
.render .section {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 10px;
}
.render.template-1 .logo.section {
  display: flex;
  align-items: center;
  justify-content: center;
}
.render.template-1 .logo.section img {
  height: 100px;
}
.render.template-1 .cta {
  display: inline-block;
  border-radius: 10px;
  padding: 10px 20px;
}
.render.template-1 .promo .title {
  max-width: 700px;
}
.render.template-1 .promo .description {
  max-width: 600px;
}
.render.template-3 .section {
  padding: 25px 0;
}
.render.template-3 .logo {
  text-align: center;
}
.render.template-3 .logo img {
  height: 40px;
}
.render.template-3 .promo {
  padding-bottom: 0;
}
.render.template-3 .promo :is(.title, .description, .cta):not(:last-child) {
  margin-bottom: 30px;
}
.render.template-3 .promo .title {
  max-width: 700px;
}
.render.template-3 .promo .description {
  max-width: 600px;
}
.render.template-3 .cta {
  display: inline-block;
}
.render.template-3 .timer {
  padding: 13px 0;
}
.render.template-3 .about {
  padding: 90px 0;
}
.render.template-3 .benefit .item {
  text-align: center;
  padding: 24px;
}
.render.template-3 .benefit .item > div {
  margin-top: 16px;
}
.render.template-3 .benefit .item .desc {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.render.template-3 .embed {
  padding-bottom: 85px;
}
.render.template-3 .embed .embed-block {
  border-radius: 20px;
  overflow: hidden;
}
.render.template-3 .footer {
  padding: 0;
}
.render.template-3 .footer .container > div {
  padding: 40px 0;
}
.render.template-3 .footer .container > div:not(:last-child) {
  border-bottom: 1px solid #3E3E3E;
}
.render.template-5 .title {
  line-height: 65px;
}
.render.template-5 .section {
  padding: 20px 0;
}
.render.template-5 .logo {
  padding-top: 60px;
}
.render.template-5 .promo .container {
  display: flex;
  gap: 30px;
}
.render.template-5 .promo .title {
  text-align: left;
}
.render.template-5 .promo .description {
  text-align: left;
  line-height: 34px;
  margin-bottom: 45px;
}
.render.template-5 .promo .cta {
  width: 230px;
}
.render.template-5 .promo .cta > div {
  justify-content: unset;
}
.render.template-5 .promo button {
  width: 230px !important;
}
.render.template-5 .timer .timer-part {
  display: flex;
  align-items: center;
  gap: 15px;
}
.render.template-5 .timer .timer-part:not(:last-child) {
  margin-right: 15px;
}
.render.template-5 .timer .timer-part .timer-value {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 50%;
  font-weight: 700 !important;
  color: #000 !important;
}
.render.template-5 .timer .timer-part .timer-label:first-letter {
  text-transform: uppercase;
}
.render.template-5 .short-horizontal-list {
  padding: 35px 0;
}
.render.template-5 .short-horizontal-list .item {
  gap: 20px;
}
.render.template-5 .short-horizontal-list .item .icon-border {
  border: 2px solid transparent;
  border-radius: 50%;
  background: linear-gradient(to right, #181818, #181818), linear-gradient(45deg, #B9AE5A 0%, #FFB800 50%, #CE8052 100%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}
.render.template-5 .short-horizontal-list .item .icon-border .q-icon {
  background: linear-gradient(45deg, #B9AE5A 0%, #FFB800 50%, #CE8052 100%);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  padding: 13px;
  font-size: 30px;
}
.render.template-5 .benefit {
  padding: 45px 0 85px;
}
.render.template-5 .benefit .description {
  color: #000 !important;
}
.render.template-5 .benefit .bottom-line {
  display: none;
}
.render.template-5 .benefit .item .q-icon {
  background: linear-gradient(90deg, #CE9537 0%, #F7EBA8 50%, #CE9537 100%);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.render.template-5 .column-list {
  background-color: #000;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 125px;
}
.render.template-5 .column-list ul {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 40px;
}
.render.template-5 .column-list .cta {
  width: 230px;
}
.render.template-5 .column-list .cta > div {
  justify-content: unset;
}
.render.template-5 .column-list button {
  width: 230px !important;
}
.render.template-5 .text-image {
  padding: 70px 0;
}
.render.template-5 .text-image .container {
  gap: 60px;
}
.render.template-5 .text-image .container .q-img {
  border-radius: 20px;
}
.render.template-5 .blockquote {
  padding: 90px 0;
}
.render.template-5 .faq {
  padding: 65px 0;
}
.render.template-5 .footer {
  padding: 40px 0;
}
.render.template-5 .footer .logo-links {
  flex-direction: column;
  gap: 30px;
  padding-bottom: 40px;
}
.render.template-5 .footer .description {
  padding-top: 40px;
  border-top: 1px solid #434343;
}
.render.template-5.thank-you .logo {
  padding: 85px 0 20px;
}
.render.template-5.thank-you .logo .container {
  text-align: center;
}
.render.template-5.thank-you .embed {
  background-position-y: -110px;
}
.render.template-5.thank-you .embed-block {
  border-radius: 20px;
  overflow: hidden;
}
.render.template-5.thank-you .embed .description {
  padding: 30px 0 36px;
}