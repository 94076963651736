@import "~common/css/fontello/fontello.css";
@import '~vue-select/dist/vue-select.css';
:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

@font-face {
  font-family: "PTSansWebRegular";
  src: url("~common/css/project-font/PT-Sans_Regular.woff2") format("woff2"), url("~common/css/project-font/PT-Sans_Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "PTSansWebRegular";
  src: url("~common/css/project-font/PT-Sans_Bold.woff2") format("woff2"), url("~common/css/project-font/PT-Sans_Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
.text-10-400 {
  font-size: 10px !important;
  font-weight: 400 !important;
}

.text-10-500 {
  font-size: 10px !important;
  font-weight: 500 !important;
}

.text-10-600 {
  font-size: 10px !important;
  font-weight: 600 !important;
}

.text-10-700 {
  font-size: 10px !important;
  font-weight: 700 !important;
}

.text-11-400 {
  font-size: 11px !important;
  font-weight: 400 !important;
}

.text-11-500 {
  font-size: 11px !important;
  font-weight: 500 !important;
}

.text-11-600 {
  font-size: 11px !important;
  font-weight: 600 !important;
}

.text-11-700 {
  font-size: 11px !important;
  font-weight: 700 !important;
}

.text-12-400 {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.text-12-500 {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.text-12-600 {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.text-12-700 {
  font-size: 12px !important;
  font-weight: 700 !important;
}

.text-13-400 {
  font-size: 13px !important;
  font-weight: 400 !important;
}

.text-13-500 {
  font-size: 13px !important;
  font-weight: 500 !important;
}

.text-13-600 {
  font-size: 13px !important;
  font-weight: 600 !important;
}

.text-13-700 {
  font-size: 13px !important;
  font-weight: 700 !important;
}

.text-14-400 {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.text-14-500 {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.text-14-600 {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.text-14-700 {
  font-size: 14px !important;
  font-weight: 700 !important;
}

.text-15-400 {
  font-size: 15px !important;
  font-weight: 400 !important;
}

.text-15-500 {
  font-size: 15px !important;
  font-weight: 500 !important;
}

.text-15-600 {
  font-size: 15px !important;
  font-weight: 600 !important;
}

.text-15-700 {
  font-size: 15px !important;
  font-weight: 700 !important;
}

.text-16-400 {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.text-16-500 {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.text-16-600 {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.text-16-700 {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.text-17-400 {
  font-size: 17px !important;
  font-weight: 400 !important;
}

.text-17-500 {
  font-size: 17px !important;
  font-weight: 500 !important;
}

.text-17-600 {
  font-size: 17px !important;
  font-weight: 600 !important;
}

.text-17-700 {
  font-size: 17px !important;
  font-weight: 700 !important;
}

.text-18-400 {
  font-size: 18px !important;
  font-weight: 400 !important;
}

.text-18-500 {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.text-18-600 {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.text-18-700 {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.text-19-400 {
  font-size: 19px !important;
  font-weight: 400 !important;
}

.text-19-500 {
  font-size: 19px !important;
  font-weight: 500 !important;
}

.text-19-600 {
  font-size: 19px !important;
  font-weight: 600 !important;
}

.text-19-700 {
  font-size: 19px !important;
  font-weight: 700 !important;
}

.text-20-400 {
  font-size: 20px !important;
  font-weight: 400 !important;
}

.text-20-500 {
  font-size: 20px !important;
  font-weight: 500 !important;
}

.text-20-600 {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.text-20-700 {
  font-size: 20px !important;
  font-weight: 700 !important;
}

.text-21-400 {
  font-size: 21px !important;
  font-weight: 400 !important;
}

.text-21-500 {
  font-size: 21px !important;
  font-weight: 500 !important;
}

.text-21-600 {
  font-size: 21px !important;
  font-weight: 600 !important;
}

.text-21-700 {
  font-size: 21px !important;
  font-weight: 700 !important;
}

.text-22-400 {
  font-size: 22px !important;
  font-weight: 400 !important;
}

.text-22-500 {
  font-size: 22px !important;
  font-weight: 500 !important;
}

.text-22-600 {
  font-size: 22px !important;
  font-weight: 600 !important;
}

.text-22-700 {
  font-size: 22px !important;
  font-weight: 700 !important;
}

.text-23-400 {
  font-size: 23px !important;
  font-weight: 400 !important;
}

.text-23-500 {
  font-size: 23px !important;
  font-weight: 500 !important;
}

.text-23-600 {
  font-size: 23px !important;
  font-weight: 600 !important;
}

.text-23-700 {
  font-size: 23px !important;
  font-weight: 700 !important;
}

.text-24-400 {
  font-size: 24px !important;
  font-weight: 400 !important;
}

.text-24-500 {
  font-size: 24px !important;
  font-weight: 500 !important;
}

.text-24-600 {
  font-size: 24px !important;
  font-weight: 600 !important;
}

.text-24-700 {
  font-size: 24px !important;
  font-weight: 700 !important;
}

.text-25-400 {
  font-size: 25px !important;
  font-weight: 400 !important;
}

.text-27-500 {
  font-size: 27px !important;
  font-weight: 500 !important;
}

.text-27-700 {
  font-size: 27px !important;
  font-weight: 700 !important;
}

.text-28-500 {
  font-size: 28px !important;
  font-weight: 500 !important;
}

.text-30-700 {
  font-size: 30px !important;
  font-weight: 700 !important;
}

.text-31-700 {
  font-size: 31px !important;
  font-weight: 700 !important;
}

.text-36-400 {
  font-size: 36px !important;
  font-weight: 400 !important;
}

.text-38-700 {
  font-size: 38px !important;
  font-weight: 700 !important;
}

.text-41-400 {
  font-size: 41px !important;
  font-weight: 400 !important;
}

:root {
  --secondary: #A3A9B8 !important;
  --dark: #041220 !important;
  --dark-1: #4f4f4f !important;
  --dark-2: #6C757E !important;
  --positive: #7BB081 !important;
  --negative: #DB6F6F !important;
  --info: #31CCEC !important;
  --warning: #F2C037 !important;
  --grey: #828990 !important;
  --grey-1: #c4c4c4 !important;
  --grey-2: #E3E3E3 !important;
  --grey-3: #F4F4F4 !important;
  --radius-3: 3px !important;
  --radius-6: 6px !important;
  --radius-8: 8px !important;
  --radius-10: 10px !important;
  --radius-12: 12px !important;
  --radius-14: 14px !important;
  --radius-16: 16px !important;
}

html, body {
  font-family: "PTSansWebRegular", sans-serif;
}

.border-radius-3 {
  border-radius: 3px !important;
}

.border-radius-6 {
  border-radius: 6px !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-radius-12 {
  border-radius: 12px !important;
}

.border-radius-14 {
  border-radius: 14px !important;
}

.border-radius-20 {
  border-radius: 20px !important;
}

.no-background {
  background: none !important;
}

.border-bottom {
  border-bottom: 1px solid #E3E3E3;
}

.border {
  border: 1px solid #E3E3E3;
}

.border-left {
  border-left: 1px solid #E3E3E3;
}

.border-right {
  border-right: 1px solid #E3E3E3;
}

.border-top {
  border-top: 1px solid #E3E3E3;
}

.border-bottom-list {
  border-bottom: 1px solid #E3E3E3;
}
.border-bottom-list:last-child {
  border-bottom: none !important;
}

.list-separator {
  border-bottom: 3px solid #E3E3E3 !important;
}

.container {
  max-width: 1890px;
  margin: 0 auto;
}

.q-card {
  border-radius: 14px;
}

.app-shadow, .q-menu {
  box-shadow: 0 0 30px rgba(7, 72, 45, 0.05);
}

.q-menu {
  border-radius: 8px;
}

.no-hover .q-focus-helper {
  opacity: 0 !important;
}
.no-hover .q-focus-helper::before, .no-hover .q-focus-helper::after {
  opacity: 0 !important;
}

.q-notification {
  box-shadow: 0 0 30px rgba(7, 72, 45, 0.05);
}

.q-loading:before {
  opacity: 1 !important;
  background: no-repeat center center url("../assets/Logo_animated.svg");
  animation: opacity 0.8s ease-in-out;
  animation-iteration-count: 1;
}

.logo-loader {
  animation: spinner 0.4s ease-in-out;
  animation-iteration-count: 1;
  background: rgba(255, 255, 255, 0.7);
}
.logo-loader .q-spinner {
  display: none !important;
}

.fields .app-input {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
}

@keyframes spinner {
  0% {
    background: rgba(255, 255, 255, 0);
  }
  100% {
    background: rgba(255, 255, 255, 0.7);
  }
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.truncated {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.q-field--outlined .q-field__control:before {
  z-index: 1;
}

.page-hint {
  display: none;
  font-size: 13px;
  width: 184px;
  position: absolute;
  top: 22px;
  left: 543px;
  color: rgba(0, 0, 0, 0.5);
}
.page-hint:before {
  content: "";
  background: #e3e3e3;
  width: 96px;
  height: 1px;
  position: absolute;
  top: 11px;
  left: -121px;
}
@media (min-width: 1440px) {
  .page-hint {
    display: block;
  }
}

.select-add-button {
  flex: 1;
}
.select-add-button .selected-option {
  display: flex;
  align-items: center;
  gap: 9px;
}
.select-add-button-content {
  border: 1px solid #E3E3E3;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select-add-button-content .q-item {
  border-top: 1px solid #E3E3E3;
}
.select-add-button-content .option {
  display: flex;
  gap: 10px;
  padding: 0 20px;
  cursor: pointer;
}
.select-add-button-content .option:hover {
  background: #F8F8F8;
}
.select-add-button-content .option-inner {
  display: flex;
  gap: 6px;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 0;
  border-top: 1px solid #E3E3E3;
  flex: 1;
}
.select-add-button-content .option-label {
  background: #F8F8F8;
  border-radius: 10px;
  padding: 6px 15px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.select-add-button-content .btn-block {
  padding: 10px 10px 10px 20px;
}
.select-add-button .q-virtual-scroll__content {
  display: flex;
  flex-direction: column;
}

.form-builder__option:not(:last-child) {
  border-bottom: 1px solid #E3E3E3;
  padding-bottom: 29px;
}
.form-builder__option:not(:first-child) {
  padding-top: 29px;
}
.form-builder__option_horizontal {
  gap: 19px;
  display: flex;
  flex-direction: column;
}
.form-builder__option_horizontal .app-input,
.form-builder__option_horizontal .app-select {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
}
.form-builder__option .q-toggle {
  justify-content: space-between;
  width: 100%;
}
@media (min-width: 0) {
  .form-builder .q-stepper__nav {
    max-width: 453px;
  }
}
@media (min-width: 1439px) {
  .form-builder .q-stepper__nav {
    width: 453px;
    max-width: unset;
  }
}

.icon-picker-position {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  align-items: center;
  gap: 10px;
}

.add-form-btn {
  border: 1px solid #00D6A2;
  border-radius: 20px !important;
}
.add-form-btn:hover {
  box-shadow: 0 0 14px rgba(0, 214, 162, 0.2);
}

.btn-primary-outline {
  font-weight: 400 !important;
}
.btn-primary-outline .q-btn__wrapper:before {
  border: 1px solid #00D6A2 !important;
}

.btn-primary .q-focus-helper {
  opacity: 0 !important;
}
.btn-primary .q-focus-helper::before, .btn-primary .q-focus-helper::after {
  opacity: 0 !important;
}
.btn-primary:hover {
  background: #00ffc2 !important;
}
.btn-primary:hover .q-btn__wrapper:before {
  border: 1px solid #00D6A2 !important;
}

.content-dialog {
  width: 500px;
}
.content-dialog .modal-header {
  background: #F8F8F8;
  border-bottom: none;
}
.content-dialog .modal-form {
  max-height: calc(100vh - 96px - 60px - 106px);
}
.content-dialog .modal-form .app-input .q-textarea .q-field__native {
  line-height: unset !important;
  padding: 16px 0 !important;
}

.chip-select_empty .q-field__control-container {
  align-items: baseline;
}
.chip-select .q-field__native {
  gap: 5px;
}
.chip-select__placeholder {
  order: -1;
}
.chip-option {
  font-size: 14px !important;
  border-radius: 6px;
  margin: 0 !important;
  padding: 6px 8px;
  display: flex;
  gap: 13px;
}
.chip-option .q-chip__icon--remove {
  color: #000 !important;
  background: #fff;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  margin: 0 !important;
  transition: background-color 0.5s ease;
}
.chip-option .q-chip__icon--remove:hover {
  background: #E3E3E3;
}

@media (min-width: 0) {
  .save-block,
.settings-page__panel,
.form-fields-page__panel,
.notifications-page__panel,
.thank-you__panel,
.autoresponders-page__panel,
.design-page__panel,
.validation-page__panel,
.code-page__panel {
    max-width: 453px;
  }
}
@media (min-width: 1439px) {
  .save-block,
.settings-page__panel,
.form-fields-page__panel,
.notifications-page__panel,
.thank-you__panel,
.autoresponders-page__panel,
.design-page__panel,
.validation-page__panel,
.code-page__panel {
    width: 453px;
    max-width: unset;
  }
}

.autoresponders-page__panel .integration {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.autoresponders-page__panel .integration__provider-field {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  gap: 20px;
}
.autoresponders-page__panel .integration__provider-field_day-cycle {
  grid-template-columns: 1fr 2fr 59px;
}
.autoresponders-page__panel .integration__provider-field .app-input,
.autoresponders-page__panel .integration__provider-field .app-select {
  display: unset;
}

.q-toggle__inner--falsy .q-toggle__track {
  background: #E3E3E3;
}

.app-input .q-field {
  align-items: center;
}

/**
 * Common builder components
 */
.app-input_textarea {
  align-items: start !important;
  grid-template-columns: 1fr 2.3fr;
}
.app-input_textarea > div {
  display: flex;
  align-items: center;
}
.app-input_textarea .q-field__control {
  padding: 15px 20px !important;
  min-height: unset;
}
.app-input_textarea .q-field__control textarea.q-field__native {
  line-height: 19px !important;
  padding: 0;
  min-height: 37px;
}

.img-selector {
  display: flex;
  justify-content: space-between;
}
.img-selector .option {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 15px;
  padding: 20px 20px 16px;
  border-radius: 10px;
  transition: all 0.5s ease;
  border: 1px solid transparent;
  cursor: pointer;
}
.img-selector .option:hover {
  background: #F8F8F8;
  border: 1px solid #F8F8F8;
}
.img-selector .active {
  border-color: #00D6A2 !important;
  background: rgba(47, 241, 195, 0.2) !important;
}
.img-selector .active .option-img {
  border-color: #00D6A2;
}
.img-selector .option-img {
  background: #fff;
  border-radius: 10px;
  border: 1px solid #E3E3E3;
  transition: all 0.5s ease;
}

.icon-selector {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: center;
  gap: 10px;
}
.icon-selector .q-btn-toggle .q-btn__content {
  font-size: 15px;
  font-weight: 400;
}
.icon-selector .q-btn-toggle .bg-btn-toggle-selected {
  background: rgba(47, 241, 195, 0.2);
  border-color: #00D6A2;
}

.toggle-buttons {
  box-shadow: none;
}
.toggle-buttons .q-btn__content {
  text-transform: capitalize !important;
}
.toggle-buttons .bg-btn-toggle-selected {
  background: rgba(47, 241, 195, 0.2);
  border-color: #00D6A2 !important;
}

.grid, .grid-3, .grid-2 {
  display: grid;
  align-items: center;
  gap: 20px;
}
.grid-2 {
  grid-template-columns: 1fr 2fr;
}
.grid-3 {
  grid-template-columns: 1.7fr 2.5fr 1fr !important;
}
.grid-3 .app-input {
  display: unset;
}