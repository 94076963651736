:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.settings-page__panel .form-data-button {
  transition: all 0.5s ease;
  border: 1px solid #E3E3E3;
  border-radius: 6px;
}
.settings-page__panel .form-data-row {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: center;
  padding: 18px 0 !important;
}
.settings-page__panel .confirm-text-input {
  align-items: start;
}
.settings-page__panel .newsletter-help-block {
  display: flex;
  gap: 10px;
  padding: 15px 70px 15px 24px;
  background: rgba(0, 214, 162, 0.2);
  border: 1px solid #00D6A2;
  border-radius: 6px;
  position: relative;
}
.settings-page__panel .newsletter-help-block:after {
  content: "";
  background: #00D6A2;
  position: absolute;
  height: 20px;
  width: 1px;
  right: 21px;
  top: -10px;
}
.settings-page__panel .newsletter-help-block span {
  font-size: 10px;
  color: #00D6A2;
  margin-top: 3px;
}
.settings-page__panel .form-builder__option_horizontal .app-input,
.settings-page__panel .form-builder__option_horizontal .app-select {
  grid-template-columns: 1fr 2.8fr;
}

.page-hint-link {
  top: 181px;
  left: 517px;
}