:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

#header {
  padding-top: 12px;
  padding-bottom: 12px;
}
@media (max-width: 1023px) {
  #header .logo {
    width: 55px;
    overflow: hidden;
  }
}
@media (max-width: 1023px) {
  #header .menu {
    justify-content: center;
  }
}
#header .menu .link {
  color: black !important;
  font-weight: bold;
  text-transform: uppercase !important;
  margin-left: 0;
  width: fit-content;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
}
#header .btn-wrapper {
  gap: 8px;
}
#header .btn-wrapper ::v-deep .q-btn__wrapper {
  padding: 5px 19px !important;
  width: 128px;
}