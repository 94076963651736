:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

.expansion ::v-deep .header {
  padding: 20px 25px;
}
.expansion ::v-deep .q-slider__track-container {
  padding: 0;
}
.expansion ::v-deep .q-slider__inner {
  background: #E3E3E3;
}
.expansion .btn-buy ::v-deep .q-btn__wrapper:before {
  border: 1px solid #00D6A2;
}
.expansion .btn-add-time ::v-deep .q-btn__content {
  font-weight: 400;
}
.expansion .arrow-img {
  top: 5px;
  right: 70px;
}