:root {
  --primary: #00D6A2 !important;
  --q-color-primary: #00D6A2 !important;
}

#partners {
  border-bottom: 1px solid #E3E3E3;
  padding-top: 60px;
  padding-bottom: 80px;
}
#partners .link {
  text-decoration: none;
}